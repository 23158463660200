import React, { createRef, useEffect } from "react";
import { Link } from "gatsby";
import { gsap } from "gsap";
import TransitionLink from "gatsby-plugin-transition-link";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "styled-components";
import Varikynat from "../../assets/images/varikynat.png";
import Vesivarit from "../../assets/images/vesivarit.png";
import Kontaktimuovi from "../../assets/images/kontaktimuovi.png";
import Siveltimet from "../../assets/images/siveltimet.png";
import Arrow from "../../assets/svg/arrowdownpink.inline.svg";
import background from "../../assets/images/varikynat.png";
import { randomColor } from "../../utils/random-color";
import { Flex, Image } from "@chakra-ui/react";

const Container = styled.div`
	width: 300px;
    padding-top: 85px;
    overflow: hidden;
    @media (min-width: 80em) {
		font-size: 2.8rem;
	}
    @media (min-width: 1100px) {
		width: 320px;
	}
`;

const Header = styled.h2`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 2rem;
	line-height: 0.94;
	text-align: left;
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: #1a1818;
	background-color: #fff;
    border-top: 3px solid #fcafc0;
	z-index: 2;
	@media (min-width: 80em) {
		font-size: 2.8rem;
		width: 370px;
	}
`;

const ImageArea = styled.div`
	position: relative;
	padding-top: 120px;
`;

const ArrowWrapper = styled.div`
	position: absolute;
    bottom: 0;
`;

const StyledLink = styled(AniLink)`
	display: flex;
	flex-direction: column;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
`;


const FrontProducts = ({
	locale
}) => {
	const arrow = createRef();
	const image1 = createRef();
	const image2 = createRef();
	const image3 = createRef();
    const image4 = createRef();

	useEffect(() => {
		gsap.set(arrow.current, { right: 2,  y: -10 });
		//gsap.to(arrow.current, { x: 155, y: -5});
		gsap.set(image1.current, { x: 0, y: -48, rotation: -10, transformOrigin: '100% 0%' });
        gsap.set(image2.current, { x: 21, y: -15, rotation: -10,  transformOrigin: '100% 0%' });
        gsap.set(image3.current, { x: 18, y: -15, rotation: -11, transformOrigin: '100% 0%' });
        gsap.set(image4.current, { x: 55, y: -15, rotation: -12,  transformOrigin: '100% 0%' });
	}, []);

	const linktext = "Tuotteet";
    
	const mouseOver = () => {
        gsap.to(arrow.current, { y: -2, duration: 0.15 });
        gsap.to(image2.current, {duration: 0.15, x: 105, y: -39, rotation: -4 });
        gsap.to(image3.current, {duration: 0.15, x: 182, y: -25, rotation: 6 });
        gsap.to(image4.current, {duration: 0.15, x: 270, y: -15, rotation: 14 });
	};
    
    
	const mouseLeave = () => {
		gsap.to(arrow.current, { duration: 0.15, y: -10 });
        gsap.to(image2.current, {duration: 0.15, x: 21, y: -15, rotation: -10 });
        gsap.to(image3.current, {duration: 0.15, x: 18, y: -15, rotation: -11 });
        gsap.to(image4.current, {duration: 0.15, x: 55, y: -15, rotation: -12 });
	};
    
	return (
		<Container>
			<StyledLink
				paintDrip
				hex={randomColor()}
				to="products"
				onMouseEnter={mouseOver}
				onMouseLeave={mouseLeave}
			>

				<ImageArea>
                    <Image pos="absolute" top="0" ref={image4} src={Kontaktimuovi} htmlWidth={37} />
                    <Image pos="absolute" top="0" ref={image3} src={Vesivarit} htmlWidth={87} />
                    <Image pos="absolute" top="0" ref={image2} src={Siveltimet} htmlWidth={80} />
                    <Image pos="absolute" top="0" ref={image1} src={Varikynat} htmlWidth={110} />
				    <ArrowWrapper ref={arrow}>	
						<Arrow />
					</ArrowWrapper>
				</ImageArea>

				<Header>{linktext}</Header>
			</StyledLink>
		</Container>
	);
};

export default FrontProducts;
